@font-face {font-family: 'Open-Sans';
  font-weight: 300;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Light.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 400;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Regular.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 500;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Medium.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 600;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-SemiBold.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 700;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Bold.ttf') format('truetype');}

@font-face {font-family: 'Ibarra-Real-Nova';
  font-weight: 300 400;
  src: url('./fonts/webfonts/Ibarra-Real-Nova/IbarraRealNova-Regular.ttf') format('truetype');}
@font-face {font-family: 'Ibarra-Real-Nova';
  font-weight: 500;
  src: url('./fonts/webfonts/Ibarra-Real-Nova/IbarraRealNova-Medium.ttf') format('truetype');}
@font-face {font-family: 'Ibarra-Real-Nova';
  font-weight: 600;
  src: url('./fonts/webfonts/Ibarra-Real-Nova/IbarraRealNova-SemiBold.ttf') format('truetype');}
@font-face {font-family: 'Ibarra-Real-Nova';
  font-weight: 700;
  src: url('./fonts/webfonts/Ibarra-Real-Nova/IbarraRealNova-Bold.ttf') format('truetype');}
