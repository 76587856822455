footer {
    .container {
        .links {
            .link {
                margin-bottom: 0px;
            }
        }
        .header-footer-col:nth-child(2) {
            img {
                -webkit-filter: invert(100%);
                filter: invert(100%);
                width: 170px;
                margin-top: 15px;
            }
        }
    }
}
